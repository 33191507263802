<template>
  <section>
    <b-card no-body>
      <app-data-table
        ref="refMailTemplatesListTable"
        table-name="mail-templates"
        :fields="fields"
        :items="fetchMails"
        :top-table="{
          showSearch: true,
          searchFilterOptions,
        }"
        :actions-table="{
          showEdit: $can('OPERATOR_MAIL_EDIT'),
        }"
        :bottom-table="{ totalCount: mailTemplatesDataMeta.totalItems }"
        @edit="$router.push({ name: 'mail-template-edit', params: { id: $event.item.id } })"
      >
        <template #cell(subject)="{ item }">
          {{ item.subject | trans }}
        </template>
      </app-data-table>
    </b-card>
  </section>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { omit } from 'lodash'

import { fetchMailsRequest } from '@/request/globalApi/requests/mailRequests'
import AppDataTable from '@/components/AppDataTable.vue'

export default defineComponent({
  name: 'MailTemplateList',

  components: { AppDataTable },

  setup(_props, ctx) {
    const { $i18n, $store } = ctx.root

    const refMailTemplatesListTable = ref(null)
    const searchFilterOptions = [
      { value: 'eventCode', text: $i18n.t('mail_template.event_code') },
      { value: 'subject', text: $i18n.t('mail_template.subject') },
    ]

    const fields = ref([
      { key: 'eventCode', label: $i18n.t('mail_template.event_code') },
      { key: 'description', label: $i18n.t('mail_template.description'), tdClass: 'w-50' },
      { key: 'subject', label: $i18n.t('mail_template.subject'), sortable: true, tdClass: 'w-50' },
    ])
    const mailTemplates = ref([])
    const mailTemplatesDataMeta = ref({
      firstPage: 1,
      lastPage: 1,
      nextPage: 1,
      previousPage: null,
      totalItems: 0,
    })

    let currentMailTemplatesList = null
    const fetchMails = (btableMeta, callback) => {
      const params = {
        locale: $store.state.appConfig.lang,
        page: btableMeta.currentPage > 1 ? btableMeta.currentPage : null,
        numberOfItemsPerPage: btableMeta.perPage,
        [btableMeta.computedSort.searchKey]: btableMeta.filter,
        // Returned order = field.key + 'Order'
        subjectOrder: btableMeta.computedSort.subjectOrder,
      }

      currentMailTemplatesList = refMailTemplatesListTable.value.localItems

      fetchMailsRequest(params)
        .then(({ data }) => {
          currentMailTemplatesList = data.mailTemplates
          mailTemplatesDataMeta.value = omit(data, 'mailTemplates')
        })
        .finally(() => {
          callback(currentMailTemplatesList)
        })
    }

    return {
      fields,
      fetchMails,
      searchFilterOptions,
      refMailTemplatesListTable,
      mailTemplates,
      mailTemplatesDataMeta,
    }
  },
})
</script>
