var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',{attrs:{"no-body":""}},[_c('app-data-table',{ref:"refMailTemplatesListTable",attrs:{"table-name":"mail-templates","fields":_vm.fields,"items":_vm.fetchMails,"top-table":{
        showSearch: true,
        searchFilterOptions: _vm.searchFilterOptions,
      },"actions-table":{
        showEdit: _vm.$can('OPERATOR_MAIL_EDIT'),
      },"bottom-table":{ totalCount: _vm.mailTemplatesDataMeta.totalItems }},on:{"edit":function($event){return _vm.$router.push({ name: 'mail-template-edit', params: { id: $event.item.id } })}},scopedSlots:_vm._u([{key:"cell(subject)",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("trans")(item.subject))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }